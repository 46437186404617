import styled from 'styled-components';

export const Root = styled.div`
  list-style-type: none;
  margin-bottom: ${(props) => props.theme.space.large_32};
`;
export const ListItem = styled.li`
  padding-left: ${(props) => props.theme.space.default_16};
  position: relative;

  &:before {
    position: absolute;
    top: 0.625rem;
    left: 0;
    content: ' ';
    height: 0.313rem;
    width: 0.313rem;
    border-radius: 100%;
    background: ${(props) => props.theme.palette.text.primary};
  }
`;
