'use client';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogin, useResendRegistrationMail } from '@Api/services/AccountService';
import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button';
import Col from '@Components/atoms/Col';
import DividerWithText from '@Components/atoms/DividerWithText/DividerWithText';
import InputEmail from '@Components/atoms/Form/InputEmail';
import InputPassword from '@Components/atoms/Form/InputPassword';
import Link from '@Components/atoms/Link';
import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import Message from '@Components/atoms/Message';
import { MessageTypes } from '@Components/atoms/Message/Message';
import Row from '@Components/atoms/Row';
import DiscordButton from '@Components/atoms/SocialAuthButtons/DiscordButton';
import FacebookButton from '@Components/atoms/SocialAuthButtons/FacebookButton';
import GoogleButton from '@Components/atoms/SocialAuthButtons/GoogleButton';
import Text from '@Components/atoms/Text';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { useInputValue } from '@Components/helper/useInputValue';
import { LocalStorageCounterKey, useLocalStorageCounter } from '@Components/helper/useLocalStorageCounter';
import { useRouterQuery } from '@Components/helper/useRouterQuery';
import Form from '@Components/molecules/Form';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { TextAlign, TextColor, TextSize } from '@Helpers/types/text';
import { Controls, HintContainer, ResendRegistrationButton, Root, SocialLoginContainer, StyledBox, StyledButton, StyledHeadline } from './Login.styles';
interface Props extends PropsWithClassName {
  referer?: string | null;
}
enum RegistrationCompleteState {
  Completed = 'completed',
  NotFound = 'not_found',
  Invalid = 'invalid',
  MailSent = 'mail_sent',
}
const Login = (props: Props): React.ReactElement => {
  const {
    t
  } = useTranslation('login');
  const [registrationCompleteState] = useRouterQuery('registration', RegistrationCompleteState);
  const [isRegistrationPending, setIsRegistrationPending] = useState<boolean>(false);
  const [email, setEmail, emailErrors, setEmailErrors, hasEmailErrors] = useInputValue<string>('', 'email');
  const [password, setPassword, passwordErrors, setPasswordErrors, hasPasswordErrors] = useInputValue<string>('', 'password');
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<MessageTypes | null>(null);
  const router = useRouter();
  const {
    loggedIn,
    login
  } = useGlobalContext();
  const {
    isFinished,
    counter,
    startCounter
  } = useLocalStorageCounter(LocalStorageCounterKey.Registration);
  const showMessage = (message: string, type: MessageTypes) => {
    setMessage(message);
    setMessageType(type);
  };
  const clearMessage = () => {
    setMessage('');
    setFormErrorMessage(null);
    setMessageType(null);
  };
  useEffect(() => {
    if (props.referer?.includes('primary-email-update-complete') && !loggedIn) {
      showMessage(t('update_primary_email.success_login_again'), MessageTypes.Success);
      return;
    }
    if (loggedIn) {
      router.push('/');
    }
  }, [loggedIn]);
  const {
    loading: loginLoading,
    executeRequest: loginExecuteRequest
  } = useLogin(result => {
    login(result.data.token, result.data.refreshToken, result.data.refreshTokenExpiration);
  }, error => {
    if (error.body?.data?.reason === 'user.not.activated') {
      setIsRegistrationPending(true);
      showMessage(error.message, MessageTypes.Info);
      return;
    }
    setFormErrorMessage(error.message);
  }, validationError => {
    setEmailErrors(validationError);
    setPasswordErrors(validationError);
  });
  const {
    loading: resendRegistrationMailLoading,
    executeRequest: resendRegistrationMailExecuteRequest
  } = useResendRegistrationMail(() => {
    resetIsRegistrationPending();
    startCounter();
    router.pushShallow(Routes.Login + '?registration=mail_sent');
  }, error => {
    showMessage(error.message, MessageTypes.Error);
  }, validationError => {
    setEmailErrors(validationError);
    setPasswordErrors(validationError);
    setIsRegistrationPending(false);
  });
  const handleSubmit = async () => {
    clearMessage();
    router.pushShallow(Routes.Login);
    await loginExecuteRequest({
      body: {
        username: email,
        password
      }
    });
  };
  useEffect(() => {
    if (!registrationCompleteState) {
      return;
    }
    switch (registrationCompleteState) {
      case RegistrationCompleteState.Completed:
        showMessage(t('notification.registration.success', {
          ns: 'signup'
        }), MessageTypes.Success);
        return;
      case RegistrationCompleteState.Invalid:
        showMessage(t('notification.registration.token.invalid', {
          ns: 'signup'
        }), MessageTypes.Error);
        return;
      case RegistrationCompleteState.NotFound:
        showMessage(t('notification.registration.token.not_found', {
          ns: 'signup'
        }), MessageTypes.Error);
        return;
      case RegistrationCompleteState.MailSent:
        showMessage(t('notification.registration.mail_sent', {
          ns: 'signup'
        }), MessageTypes.Success);
        return;
    }
  }, [registrationCompleteState]);
  const renderRegistrationMessage = () => {
    if (!messageType) {
      return;
    }
    return <Message headline={message} type={messageType} data-sentry-element="Message" data-sentry-component="renderRegistrationMessage" data-sentry-source-file="Login.tsx" />;
  };
  const showDisabledHint = () => isFinished ? null : <Text size={TextSize.Xs} color={TextColor.Tertiary} textAlign={TextAlign.Center} dataTestId="disabled-hint-root">
        {t('form.disabled_hint', {
      counter: counter
    })}
      </Text>;
  const handleResendVerificationEmail = async () => {
    await resendRegistrationMailExecuteRequest({
      body: {
        email
      }
    });
  };
  const resetIsRegistrationPending = () => {
    setIsRegistrationPending(false);
    setEmail('');
    setPassword('');
    clearMessage();
  };
  const renderContent = () => {
    if (isRegistrationPending) {
      return <>
          <Message headline={message} type={MessageTypes.Info} />
          <ResendRegistrationButton loadingIndicator={resendRegistrationMailLoading} modifier={ButtonModifier.Primary} type={ButtonType.Button} disabled={!isFinished} onClick={handleResendVerificationEmail} dataTestId="resend-submit">
            {t('form.resend.submit')}
          </ResendRegistrationButton>
          <HintContainer>{showDisabledHint()}</HintContainer>
          <StyledButton modifier={ButtonModifier.Secondary} type={ButtonType.Button} onClick={resetIsRegistrationPending} dataTestId="resend-back">
            {t('form.resend.back')}
          </StyledButton>
        </>;
    }
    return <>
        <Form onSubmit={handleSubmit} data-sentry-element="Form" data-sentry-source-file="Login.tsx">
          {renderRegistrationMessage()}
          <InputEmail value={email} disabled={false} onChange={setEmail} onBlur={setEmail} required={true} label={t('form.email.label')} forId="email" error={hasEmailErrors} errorText={emailErrors} dataTestId="login-email" data-sentry-element="InputEmail" data-sentry-source-file="Login.tsx" />
          <InputPassword value={password} name="password" disabled={false} onChange={setPassword} required={true} label={t('form.password.label')} forId="password" error={hasPasswordErrors} errorText={passwordErrors} dataTestId="login-password" data-sentry-element="InputPassword" data-sentry-source-file="Login.tsx" />
          {formErrorMessage && <Message headline={formErrorMessage} type={MessageTypes.Error} />}
          <Controls data-sentry-element="Controls" data-sentry-source-file="Login.tsx">
            <StyledButton loadingIndicator={loginLoading} disabled={false} modifier={ButtonModifier.Primary} type={ButtonType.Submit} dataTestId="login-submit" data-sentry-element="StyledButton" data-sentry-source-file="Login.tsx">
              {t('form.submit')}
            </StyledButton>
            <Link href={Routes.RecoverPassword} weight={LinkWeight.Light} size={LinkSize.Medium} dataTestId="login-recover-password" data-sentry-element="Link" data-sentry-source-file="Login.tsx">
              {t('password_forgot')}
            </Link>
          </Controls>
        </Form>
        <DividerWithText data-sentry-element="DividerWithText" data-sentry-source-file="Login.tsx">{t('divider.text')}</DividerWithText>
        <SocialLoginContainer data-sentry-element="SocialLoginContainer" data-sentry-source-file="Login.tsx">
          <GoogleButton type="link" data-sentry-element="GoogleButton" data-sentry-source-file="Login.tsx">{t('auth.google')}</GoogleButton>
          <FacebookButton type="link" data-sentry-element="FacebookButton" data-sentry-source-file="Login.tsx">{t('auth.facebook')}</FacebookButton>
          <DiscordButton type="link" data-sentry-element="DiscordButton" data-sentry-source-file="Login.tsx">{t('auth.discord')}</DiscordButton>
        </SocialLoginContainer>
      </>;
  };
  return <Root className={props.className} data-testid={'Login-root'} data-sentry-element="Root" data-sentry-component="Login" data-sentry-source-file="Login.tsx">
      <Row data-sentry-element="Row" data-sentry-source-file="Login.tsx">
        <Col xs={12} sm={6} smOffset={3} md={6} mdOffset={3} lg={4} lgOffset={4} data-sentry-element="Col" data-sentry-source-file="Login.tsx">
          <StyledHeadline importance={2} data-sentry-element="StyledHeadline" data-sentry-source-file="Login.tsx">{t('headline')}</StyledHeadline>
          <StyledBox data-sentry-element="StyledBox" data-sentry-source-file="Login.tsx">{renderContent()}</StyledBox>
        </Col>
      </Row>
    </Root>;
};
export default Login;