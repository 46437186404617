import { useCallback, useEffect, useState } from 'react';
import { deletePasswordRecoveryDate, deleteRegistrationDate, getPasswordRecoveryDate, getRegistrationDate, setPasswordRecoveryDate, setRegistrationDate } from '@Components/context/LocalStorage';
export enum LocalStorageCounterKey {
  PasswordRecovery = 'PasswordRecovery',
  Registration = 'Registration',
}
export const useLocalStorageCounter = (key: LocalStorageCounterKey, counterTime = 60) => {
  const [isFinished, setIsFinished] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(counterTime);
  const getStartDate = useCallback(() => {
    switch (key) {
      case LocalStorageCounterKey.PasswordRecovery:
        return getPasswordRecoveryDate();
      case LocalStorageCounterKey.Registration:
        return getRegistrationDate();
    }
  }, [key]);
  const setStartDate = () => {
    switch (key) {
      case LocalStorageCounterKey.PasswordRecovery:
        return setPasswordRecoveryDate(new Date());
      case LocalStorageCounterKey.Registration:
        return setRegistrationDate(new Date());
    }
  };
  const deleteStartDate = useCallback(() => {
    switch (key) {
      case LocalStorageCounterKey.PasswordRecovery:
        return deletePasswordRecoveryDate();
      case LocalStorageCounterKey.Registration:
        return deleteRegistrationDate();
    }
  }, [key]);
  useEffect(() => {
    const lastRequest = getStartDate();
    if (lastRequest === null) {
      return;
    }
    setCounter(counterTime - Math.floor((new Date().getTime() - lastRequest.getTime()) / 1000));
    setIsFinished(false);
  }, [getStartDate, counterTime]);
  useEffect(() => {
    const lastRequest = getStartDate();
    if (lastRequest === null) {
      return;
    }
    const timeout = setTimeout(() => {
      const now = new Date();
      const diff = now.getTime() - lastRequest.getTime();
      if (diff > counterTime * 1000) {
        deleteStartDate();
        setCounter(counterTime);
        setIsFinished(true);
        return;
      }
      setCounter(counter - 1);
    }, 1_000);
    return () => clearTimeout(timeout);
  }, [isFinished, counter, counterTime, deleteStartDate, getStartDate]);
  const startCounter = () => {
    setStartDate();
    setIsFinished(false);
  };
  return {
    isFinished,
    counter,
    startCounter
  };
};