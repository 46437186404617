import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './DividerWithText.styles';
interface Props extends PropsWithClassName {
  children: string;
}
const DividerWithText = (props: Props): React.ReactElement => {
  const {
    children
  } = props;
  return <Root className={props.className} data-testid={'DividerWithText-root'} data-sentry-element="Root" data-sentry-component="DividerWithText" data-sentry-source-file="DividerWithText.tsx">
      {children}
    </Root>;
};
export default DividerWithText;