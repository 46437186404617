import { IconAlertCircle, IconCircleCheck, IconCircleX, IconInfoCircle } from '@tabler/icons-react';
import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { HeadlineWrapper, Icon, MessageText, Root, StyledHeadline, StyledUnorderedList } from './Message.styles';
export enum MessageTypes {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}
interface Props extends PropsWithClassName {
  headline: string;
  type: MessageTypes;
  items?: string[];
  children?: React.ReactNode;
}
const Message = (props: Props): React.ReactElement => {
  const {
    headline,
    items,
    type,
    children,
    dataTestId = 'Message'
  } = props;
  return <Root type={type} className={props.className} data-testid={dataTestId + `-${type}-root`} data-sentry-element="Root" data-sentry-component="Message" data-sentry-source-file="Message.tsx">
      <HeadlineWrapper data-sentry-element="HeadlineWrapper" data-sentry-source-file="Message.tsx">
        <Icon type={type} data-sentry-element="Icon" data-sentry-source-file="Message.tsx">
          {type === MessageTypes.Success && <IconCircleCheck />}
          {type === MessageTypes.Error && <IconCircleX />}
          {type === MessageTypes.Warning && <IconAlertCircle />}
          {type === MessageTypes.Info && <IconInfoCircle />}
        </Icon>
        <StyledHeadline type={type} importance={4} data-sentry-element="StyledHeadline" data-sentry-source-file="Message.tsx">
          {headline}
        </StyledHeadline>
      </HeadlineWrapper>
      <MessageText data-sentry-element="MessageText" data-sentry-source-file="Message.tsx">
        {items && <StyledUnorderedList type={type} items={items} />}
        {children}
      </MessageText>
    </Root>;
};
export { Message };