import styled from 'styled-components';

import Headline from '@Components/atoms/Headline';
import UnorderedList from '@Components/atoms/UnorderedList';

import { ListItem } from '../UnorderedList/UnorderedList.styles';
import { MessageTypes } from './Message';

interface TypeProps {
  type: MessageTypes;
}

export const Root = styled.div<TypeProps>`
  margin-bottom: ${(props) => props.theme.space.large_32};
  padding: ${(props) => props.theme.space.default_16};
  border-radius: ${(props) => props.theme.borderRadius.button};
  background: ${({ type, theme }) => theme.palette.message[type].background};
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div<TypeProps>`
  margin-right: ${(props) => props.theme.space.small_8};
  display: flex;
  align-items: flex-start;

  svg {
    stroke: ${({ type, theme }) => theme.palette.message[type].text};
  }
`;

export const MessageText = styled.div`
  margin-left: ${(props) => props.theme.space.large_32};
`;

export const StyledHeadline = styled(Headline)<TypeProps>`
  margin-bottom: 0;
  color: ${({ type, theme }) => theme.palette.message[type].text};
`;

export const StyledUnorderedList = styled(UnorderedList)<TypeProps>`
  margin-top: ${(props) => props.theme.space.small_8};
  margin-bottom: 0;

  ${ListItem} {
    &:before {
      background: ${({ type, theme }) => theme.palette.message[type].text};
    }

    p {
      color: ${({ type, theme }) => theme.palette.message[type].text};
    }
  }
`;
