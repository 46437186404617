import styled from 'styled-components';

export const Root = styled.div`
  margin: ${(props) => props.theme.space.default_16} 0;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.tertiary};
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.light.small.fontSize};
  line-height: ${(props) => props.theme.font.text.light.small.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.light.small.letterSpacing};
  font-weight: ${(props) => props.theme.font.text.light.small.fontWeight};
  text-transform: uppercase;
  transition: color ${(props) => props.theme.transition.theme};

  &::before,
  &::after {
    content: '';
    height: 1px;
    background: ${(props) => props.theme.palette.divider.default};
    flex-grow: 1;
    transition: background-color ${(props) => props.theme.transition.theme};
  }

  &::before {
    margin-right: ${(props) => props.theme.space.small_8};
  }

  &::after {
    margin-left: ${(props) => props.theme.space.small_8};
  }
`;
