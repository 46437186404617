import React from 'react';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { TextSize } from '@Helpers/types/text';
import { ListItem, Root } from './UnorderedList.styles';
interface Props extends PropsWithClassName {
  items: string[] | React.ReactNode[];
}
const UnorderedList = (props: Props): React.ReactElement => {
  const {
    items
  } = props;
  return <Root className={props.className} data-testid={'UnorderedList-root'} data-sentry-element="Root" data-sentry-component="UnorderedList" data-sentry-source-file="UnorderedList.tsx">
      {items.map((item, index) => <ListItem key={index}>
          <Text size={TextSize.Medium}>{item}</Text>
        </ListItem>)}
    </Root>;
};
export { UnorderedList };