import { useState } from 'react';

import ValidationErrorModel, { ValidationMessage } from '@Api/models/ValidationErrorModel';

export const useInputValue = <S>(
  defaultValue: S,
  fields: string | string[]
): [S, (value: S) => void, ValidationMessage[], (validationError: ValidationErrorModel | null) => void, boolean] => {
  const [value, setValue] = useState<S>(defaultValue);
  const [validationError, setValidationError] = useState<ValidationErrorModel | null>(null);

  const fieldNames = Array.isArray(fields) ? fields : [fields];

  const validationErrorMessages: ValidationMessage[] = validationError?.getForFields(fieldNames) ?? [];

  const hasError = validationErrorMessages.length > 0;

  return [value, setValue, validationErrorMessages, setValidationError, hasError];
};
