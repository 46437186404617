import styled from 'styled-components';

import Box from '@Components/atoms/Box';
import Button from '@Components/atoms/Button';
import Headline from '@Components/atoms/Headline';

export const Root = styled.div``;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const ResendRegistrationButton = styled(Button)`
  width: 100%;
  margin-bottom: 0;
`;

export const HintContainer = styled.div`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const StyledHeadline = styled(Headline)`
  text-align: center;
`;

export const StyledBox = styled(Box)`
  padding: ${(props) => props.theme.space.large_32};
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
